import React, { useState } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";
import TitleSection from "../Titlesection/TitleSection";
import titleTwo from "../../data/title/title-two.json";

const [{ title, subTitle, Paragraph }] = titleTwo;

const FeaturesNav = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [featuresImage, setFeaturesImage] = useState('assets/images/features/animation/sb/0-default-feature.png');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <section
        id="extra-features-section"
        className="extra-features-section sec-ptb-160 bg-light-gray clearfix"
      >
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="8" md="8">
              {
                
                <TitleSection spacer="" textAlign="text-center">
                  <>
                  <h2 className="title-text">{title}</h2>
                  <span className="sub-title">
                    {subTitle}
                  </span>
                  </>
                </TitleSection>
          
              }
            </Col>
            <blockquote className="blockquote">
              <p className="paragraph-text mb-0">{Paragraph}</p>
            </blockquote>
          </Row>

          {/* <div className="features-nav ul-li-center mb-100">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Interaction.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  install the app
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Floder.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  input your data
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <span className="item-icon">
                    <img
                      src="assets/fonts/svg/filled/Pie_Chart.svg"
                      alt="icon_not_found"
                    />
                  </span>
                  quick data analysis
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Easy and most
                      <span className="break-point">
                        user friendly app Features
                      </span>
                    </h3>
                    <p className="mb-30">
                      Every website hosted by Appal Host is given by free fully
                      SSL certificate and Protect your website visitors with one
                      click Illustration. Lorem ipsum dolor sit amet, ectetur
                      adipisicing elit ipsum dolor sit amet.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> After you
                          purchase a hosting plan
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Next to the
                          hosting account you want to use, click Manage
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-1.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Easy and most
                      <span className="break-point">
                        user friendly app Features
                      </span>
                    </h3>
                    <p className="mb-30">
                      Every website hosted by Appal Host is given by free fully
                      SSL certificate and Protect your website visitors with one
                      click Illustration. Lorem ipsum dolor sit amet, ectetur
                      adipisicing elit ipsum dolor sit amet.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> After you
                          purchase a hosting plan
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Next to the
                          hosting account you want to use, click Manage
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-2.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="justify-content-lg-start justify-content-md-center">
                <Col sm="12" md="8" lg="6">
                  <div className="feature-content">
                    <h3 className="item-title mb-30">
                      Easy and most
                      <span className="break-point">
                        user friendly app Features
                      </span>
                    </h3>
                    <p className="mb-30">
                      Every website hosted by Appal Host is given by free fully
                      SSL certificate and Protect your website visitors with one
                      click Illustration. Lorem ipsum dolor sit amet, ectetur
                      adipisicing elit ipsum dolor sit amet.
                    </p>
                    <div className="instructions-list ul-li-block">
                      <ul className="clearfix">
                        <li>
                          <i className="flaticon-checked"></i> After you
                          purchase a hosting plan
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Next to the
                          hosting account you want to use, click Manage
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                        <li>
                          <i className="flaticon-checked"></i> Select or enter
                          the domain name you want to use.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>

                <Col sm="12" lg="6" md="8">
                  <div className="feature-image text-center">
                    <img
                      src="assets/images/features/img-3.png"
                      alt="image_not_found"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent> */}
        </Container>
        <section
      id="working-process-section"
      className={`working-process-section sec-ptb-160 clearfix`}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              {/* <span className="sub-title mb-15">{subTitle}</span> */}
              <h2 className="title-text mb-30">Features</h2>
              {/* <p className="paragraph-text mb-0">{text}</p> */}
            </TitleSection>
          </Col>
        </Row>

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item"
              onMouseEnter={() => setFeaturesImage('assets/images/features/animation/sb/1-draw-feature.png')}
              onMouseLeave={() => setFeaturesImage('assets/images/features/animation/sb/0-default-feature.png')}
            >
              <div className="item-icon">
                <img
                  src="assets/images/sb-firy-draw.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Draw your ideas</h3>
                <p className="mb-0">
                  Easily draw a vector image directly on the iPad screen.

                  Modify images, create new details or change the color with the convenience of using a PC tool.
                </p>
              </div>
            </div>

            <div className="process-item"
              onMouseEnter={() => setFeaturesImage('assets/images/features/animation/sb/2-rigging-feature.png')}
              onMouseLeave={() => setFeaturesImage('assets/images/features/animation/sb/0-default-feature.png')}
            >
              <div className="item-icon">
              <img
                  src="assets/images/sb-firy-rigging.png"
                  alt="image_not_found"
                />
                {/* <img
                  src="assets/images/inka-rigging.svg"
                  alt="image_not_found"
                /> */}
              </div>
              <div className="item-content">
                <h3 className="item-title">Rigging tool</h3>
                <p className="mb-0">
                  With 2D animation rigging you simply draw the character or
                  object in 2D and then apply the desired bone structure to it.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image">
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-out"
                data-aos-delay="300"
              >
                <img
                  src={featuresImage}
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="400"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item"
              onMouseEnter={() => setFeaturesImage('assets/images/features/animation/sb/3-animate-feature.png')}
              onMouseLeave={() => setFeaturesImage('assets/images/features/animation/sb/0-default-feature.png')}
            >
              <div className="item-icon">
                <img
                  src="assets/images/sb-firy-animation.png"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Animate - Move it</h3>
                <p className="mb-0">
                  Create animations by tapping directly on the screen, place
                  exactly how you want fluidly. Preview the animation before
                  exporting, create new skins and visualize without having to
                  animate again.
                </p>
              </div>
            </div>

            <div className="process-item"
            onMouseEnter={() => setFeaturesImage('assets/images/features/animation/sb/4-export-feature.png')}
            onMouseLeave={() => setFeaturesImage('assets/images/features/animation/sb/0-default-feature.png')}
          >
              <div className="item-icon">
                <img
                  src="assets/images/inka-export.svg"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Export - Blink and Done</h3>
                <p className="mb-0">
                  Export for all devices that you develop with support to export
                  in more than one resolution. Do not concern yourself more with
                  export image by image, do everything only once.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        {/* <div className="seemore-btn text-center">
          <Link to="#" className="custom-btn-underline">
            see all features
          </Link>
        </div> */}
      </Container>
    </section>
      </section>

    </div>
  );
};

export default FeaturesNav;
