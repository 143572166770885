import React, { Fragment } from "react";
import PageTitle from "../../components/utility";
import { LayoutHomeInka } from "../../layouts";
import { BannerTen } from "../../components/Banner";
import { BrandSliderTwo } from "../../components/BrandSlider";
import { ServiceCardOne } from "../../components/ServiceCard";
import FeaturesNav from "../../components/FeaturesNav/FeaturesNav";
import { FeatureNine } from "../../components/Features";
import { SoftwareSectionThree } from "../../components/Software";
import { WorkingProgressTwo } from "../../components/WorkingProgress";
import { TestimonialSliderTwo } from "../../components/TestimonialSlider";
import FanFact from "../../components/FuncFact/FanFact";
import PricingOne from "../../components/PricingSection/PricingSection";
import { FreeTrailOne } from "../../components/FreeTrail";
import { FaqSectionTwo } from "../../components/FaqSection";
import BlogSlider from "../../components/BLogSlider/BlogSlider";
const HomePageInka = () => {
  return (
    <Fragment>
      <PageTitle
        PageTitle="SpriteBuddy - Draw, Animate, Manage and Export"
        favicon="assets/images/logo/sb-logo-128x128.png"
      />
      <LayoutHomeInka
        activeClass="active"
        wrapperClass="home-page-10"
        sectionSpace="sec-ptb-160 pb-0"
        logoWhite="assets/images/logo/sb-logo-128x128.png"
        whitekContent="black-content"
      >
        <main>
          <BannerTen />
          {/* <BrandSliderTwo /> */}
          {/* <ServiceCardOne sectionSpace="sec-ptb-160" /> */}
          <FeaturesNav />
          {/* <WorkingProgressTwo sectionSpace="sec-ptb-160" /> */}
          {/* <FeatureNine sectionSpace="sec-ptb-160" /> */}
          <SoftwareSectionThree sectionSpace="sec-ptb-160" />
          {/* <TestimonialSliderTwo sectionSpace="sec-ptb-160 pb-0" /> */}
          {/* <FanFact /> */}
          {/* <PricingOne pricingBg="assets/images/pricing/sec-bg-1.jpg" /> */}
          {/* <FreeTrailOne sectionSpace="sec-ptb-160" /> */}
          {/* <FaqSectionTwo /> */}
          {/* <BlogSlider sectionSpace="sec-ptb-160 pb-0" /> */}
        </main>
      </LayoutHomeInka>
    </Fragment>
  );
};

export default HomePageInka;
