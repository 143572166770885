import React from "react";
import { Link } from "react-router-dom";
import TitleSection from "../Titlesection/TitleSection";
import { Container, Row, Col } from "reactstrap";

import titleOne from "../../data/WorkingProgress/working-progress-two.json";
const [{ title, subTitle, text }] = titleOne;
const workingProgressTwo = ({ sectionSpace }) => {
  return (
    <section
      id="working-process-section"
      className={`working-process-section ${sectionSpace} clearfix`}
    >
      <Container>
        {/* <Row className="justify-content-center">
          <Col sm="12" lg="6" md="8">
            <TitleSection spacer="mb-100" textAlign="text-center">
              <span className="sub-title mb-15">{subTitle}</span>
              <h2 className="title-text mb-30">{title}</h2>
              <p className="paragraph-text mb-0">{text}</p>
            </TitleSection>
          </Col>
        </Row> */}

        <Row className="justify-content-lg-start justify-content-md-center">
          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/inka-draw.svg"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Draw your ideas</h3>
                <p className="mb-0">
                  Easily draw a vector image directly on the iPad screen.

                  Modify images, create new details or change the color with the convenience of using a PC tool.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/inka-rigging.svg"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Rigging tool</h3>
                <p className="mb-0">
                  With 2D animation rigging you simply draw the character or
                  object in 2D and then apply the desired bone structure to it.
                </p>
              </div>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-phone-image">
              <span
                className="bg-image"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/images/features/animation/6-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="phone-image"
                data-aos="zoom-out"
                data-aos-delay="300"
              >
                <img
                  src="assets/images/features/animation/3-img-1.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-1"
                data-aos="fade-right"
                data-aos-delay="400"
              >
                <img
                  src="assets/images/features/animation/6-img-2.png"
                  alt="image_not_found"
                />
              </span>
              <span
                className="shape-image-2"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <img
                  src="assets/images/features/animation/6-img-4.png"
                  alt="image_not_found"
                />
              </span>
            </div>
          </Col>

          <Col lg="4" md="8" xs="12">
            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/inka-animate.svg"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Animate - Move it</h3>
                <p className="mb-0">
                  Create animations by tapping directly on the screen, place
                  exactly how you want fluidly. Preview the animation before
                  exporting, create new skins and visualize without having to
                  animate again.
                </p>
              </div>
            </div>

            <div className="process-item">
              <div className="item-icon">
                <img
                  src="assets/images/inka-export.svg"
                  alt="image_not_found"
                />
              </div>
              <div className="item-content">
                <h3 className="item-title">Export - Blink and Done</h3>
                <p className="mb-0">
                  Export for all devices that you develop with support to export
                  in more than one resolution. Do not concern yourself more with
                  export image by image, do everything only once.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="seemore-btn text-center">
          <Link to="#" className="custom-btn-underline">
            see all features
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default workingProgressTwo;
